import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="alert alert-info">
    <span className="material-icons">get_app</span> Login to <strong><a href="https://themestore.papathemes.com/bigcommerce" target="_blank">PapaThemes Store</a></strong> to download all these child themes for free when you've already purchased Supermarket theme.
    </div>
    <div className="alert alert-warning">
    <span className="material-icons">warning</span> This free child theme has limited techical support. We'll try to answer your questions about this theme, but we're not committed to answer all and fix all bugs of this theme.
    </div>
    <h1 {...{
      "id": "sima-furniture-theme"
    }}>{`Sima Furniture Theme`}</h1>
    <h2 {...{
      "id": "links-below-header"
    }}>{`Links below Header`}</h2>
    <p><img alt="Homepage Sections Manager" src={require("../img/homepage-link-below-header.png")} /></p>
    <p>{`You can enable this banner across all pages or on all other pages except Home Page in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > `}<strong parentName="p">{`Link after header`}</strong>{`:`}</p>
    <p><img alt="config banner show on home" src={require("../img/config-link-below-header.png")} /></p>
    <p>{`After enabled, you can edit banner content by go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. Input the fields as below:`}</p>
    <p><em parentName="p">{` `}<strong parentName="em">{`Banner Name`}</strong>{`: whatever.
`}</em>{` `}<strong parentName="p">{`Banner Content`}</strong>{`: click the HTML button to open HTML source code editor.`}</p>
    <p>{`Copy and paste the sample code below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div id="emthemesModezBannersBlockAfterHeader" class="emthemesModezBannersBlockAfterHeader">
    <div class="container">
        <div class="sale-list">
            <div class="sale-list__item">
                <a class="sale-list__link" title="" href="#">game day furniture sale</a>
            </div>
            <div class="sale-list__item">
                <a class="sale-list__link" title="" href="#">the entryway sale</a>
            </div>
            <div class="sale-list__item">
                <a class="sale-list__link" title="" href="#">the white sale</a>
            </div>
            <div class="sale-list__item">
                <a class="sale-list__link" title="" href="#">outdoor warm-up sale</a>
            </div>
        </div>
    </div>
</div>
`}</code></pre>
    <p>{`Set Show on `}<strong parentName="p">{`Page = Home Page`}</strong>{` and `}<strong parentName="p">{`Location = Top of Page`}</strong>{`:`}</p>
    <p><img alt="config banner show on home" src={require("../img/config-banner-show-on-home.jpg")} /></p>
    <p>{`Click `}<strong parentName="p">{`Save`}</strong>{` button to finish.`}</p>
    <h2 {...{
      "id": "sections-manager"
    }}>{`Sections Manager`}</h2>
    <p>{`You can select the sections displayed on the homepage (maximum 11 sections) in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Sections`}</strong>{`.`}</p>
    <p><img alt="Homepage Sections Manager" src={require("../img/homepage-sections-manager.png")} /></p>
    <p><strong parentName="p">{`List of available section keys:`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`featured_categories`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`listlink_banner_right`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`listlink_banner_left`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`daily_deals`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`banner_sale`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`products_by_category_sorting_tabs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`blog_recent`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`top_brands`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`live_help`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`support_info`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`instagram_carousel`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "furniture-featured-categories-section"
    }}>{`Furniture Featured Categories Section`}</h2>
    <p><img alt="Furniture Featured Categories Section" src={require("../img/sections/furniture-featured-categories-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-featured-categories-section-code.png")} /></p>
    <h2 {...{
      "id": "furniture-listlink-banner-right-section"
    }}>{`Furniture Listlink Banner Right Section`}</h2>
    <p><img alt="Furniture Listlink Banner Right Section" src={require("../img/sections/furniture-listlink-banner-right-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Listlink Banner Right`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-listlink-banner-right-section-code.png")} /></p>
    <h2 {...{
      "id": "furniture-listlink-banner-left-section"
    }}>{`Furniture Listlink Banner Left Section`}</h2>
    <p><img alt="Furniture Listlink Banner Left Section" src={require("../img/sections/furniture-listlink-banner-left-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Listlink Banner Left`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-listlink-banner-left-section-code.png")} /></p>
    <h2 {...{
      "id": "furniture-daily-deals-section"
    }}>{`Furniture Daily Deals Section`}</h2>
    <p><img alt="Furniture Daily Deals Section" src={require("../img/sections/furniture-daily-deals-section.png")} /></p>
    <p>{`You can configure this section in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Daily Deals`}</strong>{`.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/banner-daily-deals-source-editor.png")} /></p>
    <h2 {...{
      "id": "furniture-banner-sale-section"
    }}>{`Furniture Banner Sale Section`}</h2>
    <p><img alt="Furniture Banner Sale Section" src={require("../img/sections/furniture-banner-sale-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Banner Sale`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-banner-sale-section-code.png")} /></p>
    <h2 {...{
      "id": "furniture-products-by-category-sorting-tabs-section"
    }}>{`Furniture Products By Category Sorting Tabs Section`}</h2>
    <p><img alt="Furniture Banner Sale Section" src={require("../img/sections/furniture-products-by-category-sorting-tabs-section.png")} /></p>
    <p>{`This section will show products organized categories. You can choose number of categories to display products. Categories are sorted by the order specified in `}<strong parentName="p">{`Product Categories`}</strong>{` in admin manager.`}</p>
    <p>{`To configure this section, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="banner content html source editor" src={require("../img/banner-products-by-category-sorting-tabs-source-editor.png")} /></p>
    <h3 {...{
      "id": "optimize-for-execution-time"
    }}>{`OPTIMIZE FOR EXECUTION TIME`}</h3>
    <p>{`By default, all products will be loaded and displayed when opening the page. At a result, it takes a longer execution time that can affect your site's Page Speed score. To avoid this, you can select `}<strong parentName="p">{`Only load when scrolling to the viewport`}</strong>{` option, the products won't loaded until user scrolls down to this area.`}</p>
    <h3 {...{
      "id": "specify-which-categories-to-display"
    }}>{`SPECIFY WHICH CATEGORIES TO DISPLAY`}</h3>
    <p>{`By default, the product categories displayed are the top categories in the order in which they are sorted on the main navigation. You can limit number of categories to display by setting Number of Categories option.`}</p>
    <p>{`You can manually specify which categories should be displayed by setting the categories ID to Category ID box, separated by comma. For example: 200,221,254,275. Check instruction How to find the category ID. Note that Number of Categories will be ignored if you choose to display categories manually.`}</p>
    <h3 {...{
      "id": "show-banner-per-category"
    }}>{`SHOW BANNER PER CATEGORY`}</h3>
    <p>{`To show the banner image per products by category block, tick on option Show banner in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="banner content html source editor" src={require("../img/set-show-banner-category.png")} /></p>
    <h2 {...{
      "id": "furnisima-slider"
    }}>{`Furnisima Slider`}</h2>
    <p><img alt="banner content html source editor" src={require("../img/banner-furnisima-slider.png")} /></p>
    <p>{`To show these banners on homepage, go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{` editor, click `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`, input them sample content below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div data-local-banner-position="furnisima-pbcst1">
    <div class="furnisima-list">
        <div class="furnisima-list-item">
            <a class="furnisima-list-img" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                <img src="https://cdn11.bigcommerce.com/s-2zaei19psd/product_images/uploaded_images/banner-item-1.png" alt="" /> 
            </a> 
            <a class="furnisima-list-title" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                Marble Drink Table <span>Treated to an intriguingantique finish...</span> 
            </a>
        </div>
        <div class="furnisima-list-item">
            <a class="furnisima-list-img" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                <img src="https://cdn11.bigcommerce.com/s-2zaei19psd/product_images/uploaded_images/banner-item-2.png" alt="" /> 
            </a> 
            <a class="furnisima-list-title" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                Mix Console Table <span>Treated to an intriguingantique finish...</span> </a>
            </div>
        <div class="furnisima-list-item">
            <a class="furnisima-list-img" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                <img src="https://cdn11.bigcommerce.com/s-2zaei19psd/product_images/uploaded_images/banner-item-3.png" alt="" /> 
            </a> 
            <a class="furnisima-list-title" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                Intarsia Natural Table <span>Treated to an intriguingantique finish...</span> 
            </a>
        </div>
        <div class="furnisima-list-item">
            <a class="furnisima-list-img" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                <img src="https://cdn11.bigcommerce.com/s-2zaei19psd/product_images/uploaded_images/banner-item-4.png" alt="" /> 
            </a> 
            <a class="furnisima-list-title" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                Elke Table <span>Treated to an intriguingantique finish...</span> 
            </a>
        </div>
        <div class="furnisima-list-item">
            <a class="furnisima-list-img" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                <img src="https://cdn11.bigcommerce.com/s-2zaei19psd/product_images/uploaded_images/banner-item-5.png" alt="" /> 
            </a> 
            <a class="furnisima-list-title" title="" href="https://wholesale-light-demo.mybigcommerce.com/"> 
                Marble Drink Table <span>Treated to an intriguingantique finish...</span> 
            </a>
        </div>
    </div>
</div>
`}</code></pre>
    <p><img alt="banner content html source editor" src={require("../img/banner-furnisima-source-code.png")} /></p>
    <p>{`Set Show on `}<strong parentName="p">{`Page = Home Page`}</strong>{` and `}<strong parentName="p">{`Location = Bottom of Page`}</strong>{`:`}</p>
    <p><img alt="config banner show on home" src={require("../img/config-banner-show-on-home-custom.png")} /></p>
    <p>{`Click `}<strong parentName="p">{`Save`}</strong>{` button to finish.`}</p>
    <p>{`You can freely edit the HTML above to change banner images or links but keep the HTML attribute `}<inlineCode parentName="p">{`data-local-banner-position="furnisima-pbcst1"`}</inlineCode>{`, or change `}<inlineCode parentName="p">{`1`}</inlineCode>{` with numbers in the order `}<inlineCode parentName="p">{`2,3...n`}</inlineCode>{` corresponding to the order of block product by categories.`}</p>
    <p><img alt="config banner show on home" src={require("../img/furnisima-pbcst-custom.png")} /></p>
    <h2 {...{
      "id": "furniture-recent-posts-section"
    }}>{`Furniture Recent Posts Section`}</h2>
    <p><img alt="Furniture Recent Posts Section" src={require("../img/sections/furniture-recent-posts-section.png")} /></p>
    <p>{`To show Recent Posts section on `}<strong parentName="p">{`Home Page`}</strong>{`, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > set `}<strong parentName="p">{`Recent blog posts`}</strong>{` = number of posts to show up.`}</p>
    <h2 {...{
      "id": "furniture-top-brands-section"
    }}>{`Furniture Top Brands Section`}</h2>
    <p><img alt="Furniture Top Brands Section" src={require("../img/sections/furniture-top-brands-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Top Brands`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-top-brands-section-code.png")} /></p>
    <h2 {...{
      "id": "furniture-live-help-section"
    }}>{`Furniture Live Help Section`}</h2>
    <p><img alt="Furniture Top Brands Section" src={require("../img/sections/furniture-live-help-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Live Help`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-live-help-section-code.png")} /></p>
    <h2 {...{
      "id": "furniture-support-info-section"
    }}>{`Furniture Support Info Section`}</h2>
    <p><img alt="Furniture Support Info Section" src={require("../img/sections/furniture-support-info-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Live Help`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-support-info-section-code.png")} /></p>
    <h2 {...{
      "id": "furniture-instagram-section"
    }}>{`Furniture Instagram Section`}</h2>
    <p><img alt="Furniture Instagram Section" src={require("../img/sections/furniture-instagram-section.png")} /></p>
    <p>{`To show your Intagram photos on Home Page, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Instagram`}</strong>{`, input your `}<strong parentName="p">{`Access Token`}</strong>{` code and `}<strong parentName="p">{`User ID`}</strong>{`.`}</p>
    <p>{`To retrieve your Access Token, use this tool `}<a parentName="p" {...{
        "href": "https://bit.ly/2Cm0I1O"
      }}>{`https://bit.ly/2Cm0I1O`}</a></p>
    <p><img alt="Furniture Instagram Section" src={require("../img/instagram-custom.png")} /></p>
    <p>{`Here you can configure access token and number of photos.`}</p>
    <p>{`Choose `}<strong parentName="p">{`Number of photos`}</strong>{` to show up.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      